import styled from '@emotion/styled/macro';
import { Typography, Alert } from '@mui/material';
import { watermelon } from '../theme/colors';
import { defaultColor } from '../theme';
import { associateDrawerWidth } from '../../components/SuggestAssociates/constants';

export const DrawerErrorWrapper = styled.div`
  width: ${associateDrawerWidth};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${watermelon};
  height: 100%;

  button {
    margin-top: 16px;
  }
`;

export const GeneralTag = styled(Typography)`
  padding: 6px 12px;
  font-size: 14.8px;
  line-height: 1.2rem;
  font-weight: 600;
  border-radius: 5px;
  color: ${defaultColor};
`;

export const DrawerWrapper = styled.div`
  overflow-y: scroll;
  height: 100%;
  width: ${associateDrawerWidth};

  /* Add a padding to the bottom of mobile safari
     See: https://nicolas-hoizey.com/2015/02/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers.html */
  @supports (-webkit-overflow-scrolling: touch) {
    padding-bottom: 110px;
  }

  .tabs {
    z-index: 10;

    a {
      padding: 0.8em 1em;
    }
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 64px 0;
  width: ${associateDrawerWidth};
`;

export const StyledAlert = styled(Alert)`
  margin-top: 24px;
  color: #013654;
  background: #e6f3fa;
  .MuiAlert-icon {
    color: #0288d1;
  }
`;
