import { Box } from '@mui/material';
import React from 'react';

function CategoryTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box css={{ paddingLeft: '24px' }}>{children}</Box>}
    </Box>
  );
}

export default CategoryTabPanel;
