/* eslint-disable camelcase */
import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircle, Error } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { updateSingleRow } from 'components/JobTable/utils';
import { getJobDetails } from 'services/jobs';
import { determineExceptionText, determineIconDetail } from '../utils';
import { EXCEPTIONS_TO_MATCH_DETAILS } from '../../SuggestAssociates/constants';
import { LoadingWrapper } from '../../../styles/components';
import Spinner from '../../Spinner/Spinner';
import { putAssociate } from '../../../services/associates';
import { ASSOCIATE_ASSIGNED_SUCCESS_TEXT } from '../../HomePage/constants';
import { setViewAssociate } from '../../../state/ducks/viewAssociate/actions';
import { DEFAULT_VIEW_ASSOCIATE } from '../../../state/ducks/viewAssociate/constants';
import { setSuggestAssociateJob } from '../../../state/ducks/suggestAssociate/actions';
import { DEFAULT_SUGGEST_ASSOCIATE_JOB } from '../../../state/ducks/suggestAssociate/constants';
import {
  determineMatchedAssociateAssignButtonText,
  formatErrorFromSubsystem,
  getUpdatedJob,
} from '../../../lib/utils';
import { VARIANTS } from '../../../constants';
import { setSelectedEditField } from '../../../state/ducks/staffingWorkPage/actions';
import { camelToKebab } from '../../../services/case';

const buildIcon = (exception, exceptions) => {
  const { color, icon } = determineIconDetail(exception, exceptions);
  const { exception_text, text } = EXCEPTIONS_TO_MATCH_DETAILS[exception];
  const textToShow = determineExceptionText(exception_text, text, icon);
  return (
    <Box
      key={exception}
      css={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}
    >
      {icon === 'check_circle' ? (
        <CheckCircle fontSize="small" css={{ color }} />
      ) : (
        <Error fontSize="small" css={{ color }} />
      )}
      <Typography
        variant="body2"
        css={{ marginLeft: '5px' }}
        id={`match-details-${camelToKebab(exception_text)}`}
      >
        {textToShow}
      </Typography>
    </Box>
  );
};

function MatchDetails({
  exceptions,
  hideAssignButton,
  jobId,
  associate,
  jobExternalIdentifier,
  homeSystem,
  isLoading,
  setIsLoading,
  gridApiRef,
  setSelectedAssociate,
}) {
  const dispatch = useDispatch();
  const batchJobs = useSelector(
    (state) => state.staffingWorkPage.editJob.batchJobs
  );

  const handleAssignClick = (associateId) => {
    if (batchJobs.length === 1) {
      setSelectedAssociate({
        ids: [associateId],
        jobIds: batchJobs,
        name: associate.full_name,
      });
      dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
      dispatch(setSelectedEditField('associate'));
      dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
      dispatch(setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB));
      return;
    }
    setIsLoading(true);
    putAssociate({ associateId, jobId })
      .then(() => {
        enqueueSnackbar(ASSOCIATE_ASSIGNED_SUCCESS_TEXT, {
          variant: VARIANTS.success,
        });
        setIsLoading(false);
        dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
        dispatch(setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB));
        getUpdatedJob(jobId, gridApiRef, getJobDetails, updateSingleRow);
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(
          formatErrorFromSubsystem(
            'Error when assigning or un-assigning',
            error.response.data.detail,
            jobExternalIdentifier,
            homeSystem
          ),
          { variant: VARIANTS.error }
        );
      });
  };

  return (
    <Stack
      id="match-details"
      css={{
        padding: '16px',
        backgroundColor: 'white',
        borderTop: '1px solid #c6cace',
      }}
    >
      <Box
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="subtitle1"
          css={{ marginTop: '8px', marginBottom: '8px' }}
        >
          Match Details
        </Typography>
        {!hideAssignButton && (
          <Box>
            <Button
              variant="outlined"
              type="submit"
              size="small"
              className="matched-associate-details-assign-button"
              disabled={false}
              onClick={() => {
                handleAssignClick(associate.id);
              }}
            >
              <Typography
                css={{
                  fontSize: 13,
                  paddingRight: 5,
                  lineHeight: '22px',
                  padding: '0px',
                }}
                variant="button"
              >
                {determineMatchedAssociateAssignButtonText(batchJobs)}
              </Typography>
            </Button>
          </Box>
        )}
      </Box>

      {Object.keys(EXCEPTIONS_TO_MATCH_DETAILS).map((exception) =>
        buildIcon(exception, exceptions)
      )}
      {isLoading && (
        <LoadingWrapper>
          <Spinner height="60" width="60" color="rgba(0, 0, 0, 0.1)" />
        </LoadingWrapper>
      )}
    </Stack>
  );
}

export default MatchDetails;
