export const generalQuestions = [
  {
    question: 'What is Boost Central?',
    answer:
      'Boost Central is a Staffing and Scheduling system designed and built by Advantage with the goal of supporting our teams in this mission.',
  },
  {
    question: 'How does Boost know Teammates availability?',
    answer:
      'Teammates use the Boost Mobile App to enter their availability and time-off requests. This information feeds directly into Boost Central.',
  },
  {
    question: 'How do Teammates know they have work assigned to them?',
    answer:
      'Teammates use the Boost Mobile App to see their schedules. Any updates made by a staffer will show for the teammate in their app.',
  },
];

export const associatesQuestions = [
  {
    question: 'What does the number next to Associates mean?',
    answer:
      'The number next to Associates shows how many of your direct reports  have unread work preference changes. As you review work preference changes, the number will reduce.',
  },
  {
    question:
      'Why is there an orange dot next to some associates in my Associate List?',
    answer:
      'An orange dot indicates that associate has unread work preference changes. To review, click on an associate, then click the Work Preferences tab. Review the list of changes and mark them as “read” by clicking the checkbox next to each preference change. After reviewing all changes for an associate, the orange dot will disappear.',
  },
  {
    question: 'What is recent activity indicating?',
    answer:
      'Recent activity is showing the last time the associate showed activity within the Boost mobile app.',
  },
  {
    question: 'Do associates see comments added to work preference changes?',
    answer:
      'No. Comments added to work preference changes only show within Boost Central and can be used to leave comments about any communication had between the staffer and associate.',
  },
  {
    question: 'How do I contact an associate?',
    answer:
      'In the Associate Details page for an associate, there is a ‘Contact’ header next to work preferences. Clicking this header will display all the options available for contacting the associate. These options include phone number, email, and home address.',
  },
];

export const staffingWorkQuestions = [
  {
    question: 'What is the difference between Single, Batch and AI Assist?',
    answer: [
      {
        type: 'list',
        items: [
          '"Single" mode allows staffers to change one assignment at a time.',
          '"Batch" mode allows for multiple assignments to be selected, and edited, all at the same time.',
          {
            text: 'AI Assist allows multiple assignments to be selected and assigned quickly using set rules for the AI to follow when staffing.',
            subList: [
              {
                text: 'The following are available (subject to change):',
                subList: [
                  '"AI Assist – Home Store"',
                  '"AI Assist - Geography"',
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    question: 'What can AI Assisted Staffing do for me?',
    answer: [
      {
        type: 'text',
        content:
          'AI Assisted staffing can reduce the manual process of staffing. It can staff large numbers of assignments quickly which will free up staffer’s time to make specific changes.',
      },
      {
        type: 'text',
        content: 'AI Assist rules breakdown:',
        style: { fontWeight: '600' },
      },
      {
        type: 'list',
        items: [
          {
            text: 'AI Assist Universal Eligibility',
            subList: [
              'AI Assist will only staff jobs in the status of Unstaffed or On Hold. Jobs Scheduled between today and 90 days from now are eligible for AI Assist.',
              'Only Work Types of: Demonstration & Demo - Adult Beverage.',
              'Home system must be Agency or PTS/JET.',
              'Adult Beverage (AB) assignments cannot be staffed by AI Assist in the following states: Alabama (AL), Alaska (AK), Delaware (DE), Rhode Island (RI), Utah (UT), Vermont (VT), Washington (WA), Wisconsin (WI), Wyoming (WY).',
              'ES Job Shadow project jobs are excluded from AI Assist as these are flexible training assignment placeholders until assigned to specific new hires.',
            ],
          },
          {
            text: 'AI Assist Home Store Only Mode Eligibility',
            subList: [
              'Location of Assignments must have at least one person from the same Division as the Assignment with that specific location as their Home Store.',
            ],
          },
          {
            text: 'AI Assist Geography Mode Eligibility',
            subList: ['All jobs follow the "Universal" rules shown above.'],
          },
          {
            text: 'AI Assist Staffing Decisions Universal Rules',
            subList: [
              'Teammates should be available, and not booked to another job, on the day and at the time of the event.',
              'All the certifications required for the job are active for the Teammate.',
              'The state of eligibility associated to AB certification is defined by the state in which the Teammate’s home store is located, rather than their home address should these be different states.',
              'A default priority value of 1 is given to AB events and 3 is given to standard',
              'Demonstration (i.e., Grocery, non-AB). This leads to a preference for AB jobs.',
              'The job is in the same Division as the Teammate’s cost center.',
              'If the user has declined the job, it should not be auto staffed to that same user.',
              'Store Team Leads (based on cost center) are heavily prioritized.',
              'Teammates are only assigned to work in one store per day.',
              'Only front-line Teammates are eligible (cost centers beginning with 488 along with 660243, 134559, 139258, 134226, and 134557).',
              'EID must be in Active and must not belong to a 3rd party agency.',
              'Weekly hours are limited to the personalized company limit for hours in Oracle.',
              'Daily Overtime - In Alaska (AK), California (CA) and Nevada (NV) AI Assist is limited to 8 hours per day as a company limit to avoid AI Assist scheduling overtime. Teammate preference for max hours daily is also honored, up to 8 hours per day. People with home addresses outside the border of these "daily overtime" states cannot be booked into these states for Assignments by AI Assist. CA and NV, bordering each other and following the same basic daily overtime threshold of 8 hours can share freely. In the other 47 states the limit is the lesser of 10 hours per day, or the max hours limit preference set by the Teammate.',
            ],
          },
          {
            text: 'AI Assist Home Store Only Mode Staffing Decisions',
            subList: [
              'A Teammate has the location of the job assigned set as their Home Store.',
              'Leveling seeks to distribute work equally within a batch and considers already assigned work for any payroll week sent within the batch. Payroll week is defined as Sunday through Saturday. ',
            ],
          },
          {
            text: 'AI Assist Geography Mode Staffing Decisions',
            subList: [
              'First Home Store is preferred, like in Home Store Only Mode.',
              'The distance from the Teammate’s home to the location of Assignment is used when determining who is assigned to each assignment.',
              'There is a company Radius limit of 30 miles in a straight line, or 75% of the maximum miles set by the teammate in their preferences. The reduction to 75% of their preference is to approximate the driving distance relative to the straight-line distance (radius).',
            ],
          },
        ],
      },
    ],
  },
  {
    question:
      'What are the checkboxes that are shown above the assignments list?',
    answer: [
      {
        type: 'text',
        content:
          'Those are quick filters that allow staffers to see specific assignments quickly. The number showing next to a filter shows how many assignments fall under that filter.',
      },
      {
        type: 'list',
        items: [
          {
            text: 'Current “Quick Filters” (subject to change)',
            subList: ['Urgent', 'Past Due', 'Offered', 'Confirmed', 'Declined'],
          },
        ],
      },
    ],
  },
  {
    question:
      'How are associates matched to assignments (i.e. what is “Suggest Associate”)?',
    answer: [
      {
        type: 'text',
        content:
          'Suggest Associate will show you the best matches for the assignment you are staffing. The best fit will be shown at the top of the list. You will be able to see the distance the associate lives from the store and how many hours the associate has remaining for the week. We also show the last time the associate had activity within the Boost mobile app. Look for colored chips that show items like Home Store, Match or Unavailable to help make your staffing decisions. There are more colored chips that may display.',
      },
    ],
  },
  {
    question: 'I do not see an associate I expect for an assignment. Why?',
    answer: [
      {
        type: 'text',
        content:
          'Associates will show based on how well they match the assignment. If you do not see associates you expect to see, you can use Advanced Search to adjust what is shown. If you turn off some filters within Advanced Search, more associates may show but may not be the best matches for that assignment.',
      },
    ],
  },
  {
    question: 'Are my filters saved?',
    answer: [
      {
        type: 'text',
        content:
          'Yes. We save your last filters set so the next time you are staffing work; your previous filters are still set. You can quickly clear all filters by clicking the X by the Applied Filters at the top of the screen.',
      },
    ],
  },
  {
    question: 'Is there a way to undo batch changes?',
    answer: [
      {
        type: 'text',
        content:
          'Yes. When viewing the batch details screen, you can click the Edit Assignments button to make changes to any/all assignments within that batch. If necessary, you can change to batch mode, select all assignments and un-staff all of them at once.',
      },
    ],
  },
];
