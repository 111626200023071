import React from 'react';
import { Box } from '@mui/material';
import QuestionAccordion from './QuestionAccordion';
import { staffingWorkQuestions } from '../constants';
import StaffingWorkAnswer from './StaffingWorkAnswer';

function StaffingWorkQuestions() {
  return (
    <Box id="staffing-work-questions-and-answers">
      {staffingWorkQuestions.map(({ question, answer }, index) => (
        <QuestionAccordion
          defaultExpanded={index === 0}
          key={index}
          question={question}
        >
          <StaffingWorkAnswer answer={answer} />
        </QuestionAccordion>
      ))}
    </Box>
  );
}

export default StaffingWorkQuestions;
