import React from 'react';
import { Box, Typography, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import PageLayout from 'components/PageLayout/PageLayout';

import Breadcrumb from 'components/BreadCrumb/BreadCrumb';
import { VIEWS } from 'core/config';

import Footer from 'components/HelpPage/components/Footer';
import CategoryTab from './components/CategoryTab';
import GeneralQuestions from './components/GeneralQuestions';
import StaffingWorkQuestions from './components/StaffingWorkQuestions';
import AssociatesQuestions from './components/AssociatesQuestions';
import CategoryTabPanel from './components/CategoryTabPanel';

function FAQPage() {
  const theme = useTheme();
  const maxWidth = '960px';
  const [activeTab, setActiveTab] = React.useState(0);

  const breadcrumbLinks = [{ linkText: 'Help Center', href: '/help' }];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <PageLayout>
      <Box
        css={{
          backgroundColor: theme.palette.backgroundColor.primary,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          css={{
            backgroundColor: 'white',
            width: '100%',
            boxSizing: 'border-box',
            paddingBottom: '16px',
            borderBottom: `1px solid #c6cace`,
          }}
        >
          <Breadcrumb
            linkText="Help Center"
            detailsText="FAQs"
            breadcrumbLinks={breadcrumbLinks}
            href={VIEWS.private.help}
            cssOverride={{
              paddingLeft: '24px',
              paddingTop: '16px',
            }}
          />
        </Box>
        <Typography
          variant="h4"
          css={{ marginBottom: '24px', marginTop: '64px' }}
        >
          Frequently Asked Questions
        </Typography>
        <Typography variant="body1">
          Have questions? We’re here to help.
        </Typography>

        <Box css={{ marginTop: '64px' }}>
          <Box css={{ width: '100%', maxWidth: '960px', display: 'flex' }}>
            <Tabs
              value={activeTab}
              orientation="vertical"
              onChange={handleTabChange}
              css={{
                '& .MuiTabs-indicator': { display: 'none' },
                minWidth: '224px',
              }}
            >
              <CategoryTab
                title="General"
                subtitle="Basic questions about the Boost application."
                id="general-category"
              />
              <CategoryTab
                title="Staffing Work"
                subtitle="Staffing assignments specific questions."
                id="staffing-work-category"
              />
              <CategoryTab
                title="Associates"
                subtitle="Questions about associate related screens."
                id="associates-category"
              />
            </Tabs>
            <Box css={{ flexGrow: 1 }}>
              <CategoryTabPanel value={activeTab} index={0}>
                <GeneralQuestions />
              </CategoryTabPanel>
              <CategoryTabPanel value={activeTab} index={1}>
                <StaffingWorkQuestions />
              </CategoryTabPanel>
              <CategoryTabPanel value={activeTab} index={2}>
                <AssociatesQuestions />
              </CategoryTabPanel>
            </Box>
          </Box>

          <Footer maxWidth={maxWidth} />
        </Box>
      </Box>
    </PageLayout>
  );
}

export default FAQPage;
