import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

import LinkCard from './LinkCard';
import { faqDetails, supportTicketDetails } from '../contants';

function Content({ maxWidth }) {
  const flagName = 'use_priority';
  const showInternalFAQPage = useSelector(
    (state) => state.featureFlags[flagName]
  );

  return (
    <Box
      css={{
        marginTop: '64px',
        maxWidth,
      }}
    >
      <Typography
        css={{
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
        as="h2"
        variant="h6"
      >
        Categories
      </Typography>
      <Box
        css={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          columnGap: '24px',
          width: '100%',
          '@media (max-width: 1675px)': {
            justifyContent: 'center',
          },
        }}
      >
        <LinkCard
          id="faq-card"
          {...faqDetails}
          url={
            showInternalFAQPage
              ? faqDetails.url
              : 'https://advantageprod.service-now.com/esc?id=kb_article&table=kb_knowledge&sysparm_article=KB0010105'
          }
          externalLink={!showInternalFAQPage}
        />
        <LinkCard id="support-card" {...supportTicketDetails} externalLink />
      </Box>
    </Box>
  );
}

export default Content;
