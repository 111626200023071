import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';

function StaffingWorkAnswer({ answer }) {
  return answer.map(({ type, content, style, items }, answerIndex) => {
    if (type === 'text') {
      return (
        <Typography key={answerIndex} variant="body1" css={style || {}}>
          {content}
        </Typography>
      );
    }

    return (
      <List
        key={answerIndex}
        css={{ listStyleType: 'disc', padding: '8px 16px 16px' }}
      >
        {items.map((listItem, itemIndex) => (
          <ListItem
            disablePadding
            disableGutters
            key={itemIndex}
            css={{ display: 'list-item' }}
          >
            {typeof listItem === 'string' ? (
              <ListItemText primary={listItem} />
            ) : (
              <>
                <ListItemText primary={listItem.text} />
                {listItem.subList && (
                  <List
                    css={{ listStyleType: 'disc', padding: '0px 16px 0px' }}
                  >
                    {listItem.subList.map((subListItem, listItemIndex) => (
                      <ListItem
                        key={listItemIndex}
                        disablePadding
                        disableGutters
                        css={{ display: 'list-item' }}
                      >
                        {typeof subListItem === 'string' ? (
                          <ListItemText primary={subListItem} />
                        ) : (
                          <>
                            <ListItemText primary={subListItem.text} />
                            {subListItem.subList && (
                              <List
                                css={{
                                  listStyleType: 'disc',
                                  padding: '0px 16px 0px',
                                }}
                              >
                                {subListItem.subList.map(
                                  (nestedSubListItem, subItemIndex) => (
                                    <ListItem
                                      key={subItemIndex}
                                      disablePadding
                                      disableGutters
                                      css={{ display: 'list-item' }}
                                    >
                                      <ListItemText
                                        primary={nestedSubListItem}
                                      />
                                    </ListItem>
                                  )
                                )}
                              </List>
                            )}
                          </>
                        )}
                      </ListItem>
                    ))}
                  </List>
                )}
              </>
            )}
          </ListItem>
        ))}
      </List>
    );
  });
}

export default StaffingWorkAnswer;
