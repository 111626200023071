import React from 'react';
import PageLayout from '../PageLayout/PageLayout';
import Help from './components/Help';

function HelpPage() {
  return (
    <PageLayout>
      <Help />
    </PageLayout>
  );
}

export default HelpPage;
