import React from 'react';
import { Typography, Box } from '@mui/material';
import QuestionAccordion from './QuestionAccordion';
import { generalQuestions } from '../constants';

function GeneralQuestions() {
  return (
    <Box id="general-questions-and-answers">
      {generalQuestions.map(({ question, answer }, index) => (
        <QuestionAccordion
          key={index}
          defaultExpanded={index === 0}
          question={question}
        >
          <Typography variant="body1">{answer}</Typography>
        </QuestionAccordion>
      ))}
    </Box>
  );
}

export default GeneralQuestions;
