import { HelpCenterOutlined, LocalActivityOutlined } from '@mui/icons-material';
import React from 'react';

export const faqDetails = {
  title: 'FAQs',
  description:
    'Visit our FAQs section for answers to common questions and information on using the Boost app.',
  url: '/help/faq',
  icon: (theme) => (
    <HelpCenterOutlined
      fontSize="large"
      css={{
        marginBottom: '14px',
        color: theme.palette.primary.main,
      }}
    />
  ),
};

export const supportTicketDetails = {
  title: 'Helpdesk Tickets',
  description:
    'Submit a request for assistance or keep track of your open tickets in this section.',
  url: 'https://advantageprod.service-now.com/esc?id=sc_cat_item&sys_id=6714d53c93d48690a0a6b80e1dba108f',
  icon: (theme) => (
    <LocalActivityOutlined
      fontSize="large"
      css={{
        marginBottom: '14px',
        color: theme.palette.primary.main,
      }}
    />
  ),
};
