import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Box } from '@mui/system';
import store from 'store2';
import { useTheme } from '@mui/material/styles';
import {
  StyledDialog,
  StyledDialogButton,
  StyledDialogContextText,
  StyledDialogTitle,
} from './style';
import { VIEWS } from '../../core/config';
import { DEFAULT_AUTO_STAFFING } from '../../state/ducks/dialog/constants';
import { setAutoStaffing } from '../../state/ducks/dialog/actions';
import { shouldHideAutoStaffingDialog } from './utils';

function AutoStaffingDialog() {
  const autoStaffing = useSelector((state) => state.dialog.autoStaffing);
  const theme = useTheme();
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const dispatch = useDispatch();
  const flagName = 'use_priority';
  const showInternalFAQPage = useSelector(
    (state) => state.featureFlags[flagName]
  );

  if (isEmpty(autoStaffing)) {
    return null;
  }

  const cachedDialogKey = autoStaffing.dialog.cacheKey;

  const onClose = () => {
    if (dontShowAgain) {
      store.set(cachedDialogKey, autoStaffing.dialog.revisedDate);
    }
    dispatch(setAutoStaffing(DEFAULT_AUTO_STAFFING));
    setDontShowAgain(false);
  };

  const cachedDialogDate = store.get(cachedDialogKey);

  const {
    dialog: { title, description, subTitles, revisedDate },
  } = autoStaffing;

  return shouldHideAutoStaffingDialog(cachedDialogDate, revisedDate) ? null : (
    <StyledDialog
      open={!isEmpty(autoStaffing)}
      onClose={() => {
        onClose();
      }}
      id="auto-staffing-dialog"
    >
      <StyledDialogTitle id="auto-staffing-dialog-title">
        {title}
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContextText id="auto-staffing-dialog-text">
          {description}
        </StyledDialogContextText>
        {revisedDate && (
          <Typography
            css={{ fontWeight: 700 }}
          >{`(Revised: ${revisedDate})`}</Typography>
        )}
        <Box>
          {subTitles.map((subtitle) => (
            <Box key={subtitle.title} css={{ paddingTop: '8px' }}>
              <Typography variant="subtitle1">{subtitle.title}</Typography>
              <List
                sx={{
                  listStyleType: 'disc',
                  pl: 3,
                  paddingTop: '0',
                  paddingBottom: '0',
                }}
              >
                {subtitle.listOfText.map((text) => (
                  <ListItem
                    key={text}
                    disablePadding
                    sx={{ display: 'list-item' }}
                  >
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
          <FormControlLabel
            control={
              <Checkbox
                id="auto-staffing-dialog-checkbox"
                onChange={({ target: { checked } }) => {
                  setDontShowAgain(checked);
                }}
              />
            }
            css={{ display: 'flex', alignItems: 'flex-start' }}
            label={
              <Box>
                <Typography css={{ marginTop: '8px' }} variant="body1">
                  Don&apos;t show this again
                </Typography>
                {showInternalFAQPage && (
                  <Typography
                    css={{ color: theme.palette.text.secondary }}
                    variant="body2"
                  >
                    Requirements can be reviewed in the{' '}
                    <Link
                      css={{ color: theme.palette.primary.main }}
                      to={VIEWS.private.faq}
                      id="dialog-faq-link"
                    >
                      Frequently Asked Questions
                    </Link>{' '}
                    section of the Help screen.
                  </Typography>
                )}
              </Box>
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <StyledDialogButton
          id="auto-staffing-dialog-close"
          variant="text"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </StyledDialogButton>
      </DialogActions>
    </StyledDialog>
  );
}

export default AutoStaffingDialog;
