import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { LaunchOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

function LinkCard({ externalLink, description, icon, id, title, url }) {
  const theme = useTheme();
  return (
    <Link
      id={id}
      to={url}
      target={externalLink && '_blank'}
      rel="noopener noreferrer"
      css={{ textDecoration: 'none', marginBottom: '12px' }}
    >
      <Paper
        square={false}
        elevation={1}
        css={{
          position: 'relative',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '460px',
          '&:hover': {
            boxShadow: theme.shadows[5],
          },
        }}
      >
        {externalLink && (
          <LaunchOutlined
            fontSize="xsmall"
            css={{
              position: 'absolute',
              top: '16%',
              right: '4%',
              transform: 'translateY(-50%)',
              color: theme.palette.text.secondary,
            }}
          />
        )}
        <Box
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '24px',
            textAlign: 'center',
          }}
        >
          {icon(theme)}
          <Typography variant="subtitle3">{title}</Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>
      </Paper>
    </Link>
  );
}

export default LinkCard;
