import React from 'react';
import { Box, Typography, Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function CategoryTab(props) {
  const theme = useTheme();
  const { title, subtitle, value, id, ...rest } = props;

  return (
    <Tab
      {...rest}
      id={id}
      value={value}
      label={
        <Box sx={{ textAlign: 'left' }}>
          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontWeight: '600',
              textTransform: 'none',
              opacity: '1 !important',
            }}
            variant="subtitle3"
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              textTransform: 'none',
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      }
      css={{
        backgroundColor: 'white',
        maxWidth: '224px',
        maxHeight: '100px',
        marginBottom: '8px',
        borderRadius: '4px',
        boxSizing: 'border-box',
        '&.MuiTab-root': {
          alignItems: 'flex-start',
          textTransform: 'none',
          padding: '16px',
        },
        '&.MuiButtonBase-root': {
          opacity: '1 !important',
        },
        '&.Mui-selected': {
          border: '1px solid #00799E',
        },
      }}
    />
  );
}

export default CategoryTab;
