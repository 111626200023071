import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import Unauthorized from '../../components/Unauthorized/Unauthorized';
import Loading from './Loading';

function AuthWrapper() {
  const { oktaAuth, authState } = useOktaAuth();
  const isAuthorized = useSelector((state) => state.access.isAuthorized);

  useEffect(() => {
    if (authState && !authState.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState]);

  if (!authState || !authState.isAuthenticated) {
    return <Loading />;
  }

  if (isAuthorized === false) {
    return <Unauthorized />;
  }

  return <Outlet />;
}

export default AuthWrapper;
