/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { Box } from '@mui/system';
import { Divider, Typography } from '@mui/material';
import { utcToZonedTime } from 'date-fns-tz';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { isArray } from 'lodash';
import { updateSingleRow } from 'components/JobTable/utils';
import { getUpdatedJob } from 'lib/utils';
import { getJobDetails } from 'services/jobs';
import { sendBatchRequest } from '../../services/batch';
import {
  setBatchId,
  setBatchJobs,
  setFocusedJobId,
  setIsSelectAllPages,
  setSelectAllPageCount,
  setSelectedEditField,
} from '../../state/ducks/staffingWorkPage/actions';
import {
  convertToMinutes,
  determineAssociateToDisplay,
  determineDateToDisplay,
  determineDurationToDisplay,
  determineIfDateIsWithinRange,
  determineMutation,
  determineRequest,
  determineSingleMutation,
  determineSingleRequest,
  determineTimeToDisplay,
  editToastMessage,
  isReassignmentErrored,
  isSaveButtonDisabled,
} from './utils';
import AssociateEdit from './components/AssociateEdit';
import { VARIANTS } from '../../constants';
import ReassignmentReason from './components/ReassignmentReason';
import DateEdit from './components/DateEdit';
import TimeEdit from './components/TimeEdit';
import DurationEdit from './components/DurationEdit';
import BatchNameTextField from './components/BatchNameTextField';
import Submission from './components/Submission';
import BatchHeader from './components/BatchHeader';
import AssignmentSelected from './components/AssignmentSelected';
import BatchEditLabel from './components/BatchEditLabel';
import DoesNotAllowDateTimeAndDurationEditAlert from './components/DoesNotAllowDateTimeAndDurationEditAlert';
import AutoHomeStoreOnlyAlert from './components/AutoHomeStoreOnlyAlert';

function EditJobDrawer({
  selectedAssociate,
  setSelectedAssociate,
  isOpen,
  editField,
  gridApiRef,
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [batchName, setBatchName] = useState('');
  const [dateEdit, setDateEdit] = useState(false);
  const [timeEdit, setTimeEdit] = useState(false);
  const [associateEdit, setAssociateEdit] = useState(false);
  const [durationEdit, setDurationEdit] = useState(false);
  const [initialAssociate, setInitialAssociate] = useState(selectedAssociate);
  const {
    batchJobs,
    isSelectAllPages,
    isSelectAllPagesLoading,
    selectAllPagesCount,
    batchId,
    includesAutoStaffedJob,
    includedSourceSystems,
  } = useSelector((state) => state.staffingWorkPage.editJob);
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const batchJob = batchJobs[0];

  const currentMode = Array.from(centralMode.values())[0];
  const isBatchModeOn = currentMode === 'batch';
  const isSingleModeOn = currentMode === 'single';
  const isAutoModeOn = currentMode.startsWith('auto');
  const shouldShowBatchNameInput = !isSingleModeOn;
  const isSingleOrBatch = isSingleModeOn || isBatchModeOn;
  const homeSystem = batchJob?.advantage_source_system_name;
  const homeSystemsThatDoNotAllowDateTimeDurationEditing = [
    'Retail Logic',
    'SRS',
    'Agency',
  ];
  const isDateTimeDurationEditable =
    !batchJobs.some((job) =>
      homeSystemsThatDoNotAllowDateTimeDurationEditing.includes(
        job.advantage_source_system_name
      )
    ) && !includedSourceSystems.includes('agency');
  const [initialDate, setInitialDate] = useState({ view: '', edit: null });
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [initialTime, setInitialTime] = useState({ view: '', edit: null });
  const [selectedTime, setSelectedTime] = useState(initialDate);
  const [errorDate, setErrorDate] = useState(null);
  const [errorTime, setErrorTime] = useState(null);
  const [initialDurationHours, setInitialDurationHours] = useState('');
  const [selectedDurationHours, setSelectedDurationHours] =
    useState(initialDurationHours);
  const [initialDurationMinutes, setInitialDurationMinutes] = useState('');
  const [selectedDurationMinutes, setSelectedDurationMinutes] = useState(
    initialDurationMinutes
  );
  const [isDurationValid, setIsDurationValid] = useState(true);
  const [durationView, setDurationView] = useState('');
  const [selectedReassignmentReason, setSelectedReassignmentReason] = useState(
    {}
  );
  const [otherReason, setOtherReason] = useState('');
  const batchJobsCount = batchJobs.length;

  const countToDisplay =
    selectAllPagesCount !== 0 ? selectAllPagesCount : batchJobsCount;
  const isDurationHoursZeroOrEmpty =
    selectedDurationHours === '0' || selectedDurationHours === '';
  const isDurationMinutesZero = selectedDurationMinutes === '0';
  const isDurationMinutesEmpty = selectedDurationMinutes === '';
  const isDurationEmpty =
    durationEdit &&
    ((isDurationHoursZeroOrEmpty && isDurationMinutesZero) ||
      isDurationMinutesEmpty);
  const theme = useTheme();
  const batchJobIds = batchJobs.map(({ id }) => id);
  const params = {
    associate: {
      old: initialAssociate,
      new: selectedAssociate,
    },
    startDate: {
      old:
        initialDate.edit && !isSingleModeOn
          ? format(initialDate.edit, 'yyyy-MM-dd')
          : initialDate.edit,
      new:
        selectedDate.edit && !isSingleModeOn
          ? format(selectedDate.edit, 'yyyy-MM-dd')
          : selectedDate.edit,
    },
    startTime: {
      old: initialTime.edit
        ? format(initialTime.edit, 'HH:mm')
        : initialTime.edit,
      new: selectedTime.edit
        ? format(selectedTime.edit, 'HH:mm')
        : selectedTime.edit,
    },
    duration: {
      old: convertToMinutes(initialDurationHours, initialDurationMinutes),
      new: convertToMinutes(selectedDurationHours, selectedDurationMinutes),
    },
    reassignmentReason: {
      reasonId: selectedReassignmentReason.id,
      otherReason,
    },
  };
  const showReassignmentReason =
    includesAutoStaffedJob &&
    params.associate.old.name !== params.associate.new.name;

  const handleClose = () => {
    dispatch(setSelectedEditField(null));
    if (isSingleModeOn) {
      dispatch(setBatchJobs([]));
    }
    setTimeout(() => {
      dispatch(setFocusedJobId(null));
      setSelectedAssociate(initialAssociate);
      setDateEdit(false);
      setSelectedDate(initialDate);
      setTimeEdit(false);
      setSelectedTime(initialTime);
      setDurationEdit(false);
      setAssociateEdit(false);
      setSelectedDurationHours(initialDurationHours);
      setSelectedDurationMinutes(initialDurationMinutes);
      setBatchName('');
      setErrorDate(null);
      setErrorTime(null);
      setSelectedReassignmentReason({});
      setOtherReason('');
    }, theme.transitions.duration.leavingScreen);
  };

  const determineEditField = () => {
    switch (editField) {
      case 'associate':
        return setAssociateEdit(true);
      case 'date':
        return setDateEdit(true);
      case 'time':
        return setTimeEdit(true);
      default:
        return null;
    }
  };

  useEffect(() => {
    if (batchJobs.length > 0) {
      determineEditField();
      const associate = determineAssociateToDisplay(
        batchJobs,
        isSelectAllPages
      );
      setInitialAssociate(associate);
      setSelectedAssociate(associate);
      const dateToDisplay = determineDateToDisplay(
        batchJobs.map((job) =>
          utcToZonedTime(job.job_start_after, job.store_location_timezone)
        ),
        isSelectAllPages
      );
      setInitialDate(dateToDisplay);
      setSelectedDate(dateToDisplay);

      const timeToDisplay = determineTimeToDisplay(
        batchJobs.map((job) => {
          const timeZone = job.store_location_timezone;
          return utcToZonedTime(job.job_start_after, timeZone);
        }),
        isSelectAllPages
      );
      setInitialTime(timeToDisplay);
      setSelectedTime(timeToDisplay);

      const durationToDisplay = determineDurationToDisplay(
        batchJobs.map((job) => job.estimated_time),
        isSelectAllPages
      );
      setInitialDurationHours(durationToDisplay.edit.hours);
      setSelectedDurationHours(durationToDisplay.edit.hours);
      setInitialDurationMinutes(durationToDisplay.edit.minutes);
      setSelectedDurationMinutes(durationToDisplay.edit.minutes);
      setDurationView(durationToDisplay.view);
    }
  }, [batchJobs, isSelectAllPages]);

  const dispatchSuccessToast = (name) => {
    const prettyName = (
      <Typography variant="subtitle2" as="span" color="white">
        {name}
      </Typography>
    );
    enqueueSnackbar(
      <Typography variant="body2" as="span" color="white">
        Your batch, {prettyName}, has been created and is processing.
      </Typography>,
      { variant: VARIANTS.info }
    );
  };

  const saveSingle = () => {
    const url = determineSingleMutation(params.associate);
    const request = determineSingleRequest(
      `/jobs/${batchJob.id}/${url}`,
      params,
      batchJob.store_location_timezone
    );

    setIsLoading(true);
    sendBatchRequest('put', request)
      .then(() => {
        getUpdatedJob(batchJob.id, gridApiRef, getJobDetails, updateSingleRow);
        handleClose();
        const successToastMessage = editToastMessage(
          url,
          batchJob.external_identifier,
          params.associate.new.name
        );
        enqueueSnackbar(successToastMessage, {
          variant: VARIANTS.success,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        const determineErrorMessage = () => {
          if (isArray(error.detail)) {
            return error.detail.join(' ');
          }
          if (error.detail) {
            return error.detail;
          }
          return `Something went wrong updating Assignment ID ${batchJob.external_identifier}.`;
        };
        enqueueSnackbar(determineErrorMessage(), {
          variant: VARIANTS.error,
        });

        setIsLoading(false);
      });
  };

  const isSaveDisabled =
    (isSingleOrBatch &&
      isSaveButtonDisabled(
        params,
        isLoading,
        isSelectAllPagesLoading,
        isDurationEmpty,
        errorDate,
        errorTime,
        isDurationValid
      )) ||
    (showReassignmentReason &&
      isReassignmentErrored(
        includesAutoStaffedJob,
        selectedReassignmentReason,
        otherReason
      ));

  const saveBatch = () => {
    const mutation = determineMutation(params.associate, isBatchModeOn);
    const request = determineRequest(
      `/bulk/batch/${mutation}`,
      params,
      currentMode,
      batchJobIds,
      batchId,
      !batchName
        ? `${isAutoModeOn ? 'AI Assist' : 'Batch'} ${format(new Date(), 'MM/dd/yyyy hh:mm:ss a')}`
        : batchName
    );
    setIsLoading(true);
    sendBatchRequest('post', request)
      .then((name) => {
        handleClose();
        dispatchSuccessToast(name);
        dispatch(setBatchJobs([]));
        dispatch(setIsSelectAllPages(false));
        dispatch(setBatchId(null));
        dispatch(setSelectAllPageCount(0));
        setIsLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar(error.detail || 'Your batch has failed.', {
          variant: VARIANTS.error,
        });
        setIsLoading(false);
      });
  };

  const handleSave = () => {
    if (isSingleModeOn) {
      saveSingle();
      return;
    }
    saveBatch();
  };

  const isDateWithinRange = determineIfDateIsWithinRange(
    selectedDate.edit,
    utcToZonedTime(
      batchJob?.created_job_start_after,
      batchJob?.store_location_timezone
    ),
    utcToZonedTime(
      batchJob?.created_job_finish_before,
      batchJob?.store_location_timezone
    ),
    isOpen,
    isSingleModeOn
  );

  return (
    <Drawer
      anchor="right"
      onClose={() => {
        handleClose();
      }}
      open={isOpen}
      css={{ zIndex: theme.zIndex.drawer + 1 }}
      PaperProps={{
        id: 'batch-edit-drawer',
        sx: {
          width: '400px',
          display: 'flex',
          justifyContent: 'space-between',
        },
      }}
    >
      <Box>
        <BatchHeader currentMode={currentMode} onClick={handleClose} />
        <Divider />
        <Box css={{ padding: '16px' }}>
          <AssignmentSelected
            selectAllPagesLoading={isSelectAllPagesLoading}
            countToDisplay={countToDisplay}
          />
          <BatchEditLabel />
          <AutoHomeStoreOnlyAlert />
          {isSingleOrBatch && (
            <>
              <AssociateEdit
                batchJob={batchJob}
                associateEdit={associateEdit}
                setAssociateEdit={setAssociateEdit}
                selectedAssociate={selectedAssociate}
                setSelectedAssociate={setSelectedAssociate}
                isLoading={isLoading}
                isSingleModeOn={isSingleModeOn}
                homeSystem={homeSystem}
              />
              <DoesNotAllowDateTimeAndDurationEditAlert
                isDateTimeDurationEditable={isDateTimeDurationEditable}
                batchJobs={batchJobs}
              />
              <DateEdit
                dateEdit={dateEdit}
                setDateEdit={setDateEdit}
                setTimeEdit={setTimeEdit}
                errorDate={errorDate}
                setErrorDate={setErrorDate}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                isOpen={isOpen}
                isLoading={isLoading}
                isEditable={isDateTimeDurationEditable}
              />
              <TimeEdit
                timeEdit={timeEdit}
                setTimeEdit={setTimeEdit}
                dateEdit={dateEdit}
                setDateEdit={setDateEdit}
                selectedTime={selectedTime}
                errorTime={errorTime}
                setErrorTime={setErrorTime}
                errorDate={errorDate}
                setSelectedTime={setSelectedTime}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                isLoading={isLoading}
                isDateWithinRange={isDateWithinRange}
                isEditable={isDateTimeDurationEditable}
              />
              <DurationEdit
                durationEdit={durationEdit}
                setDurationEdit={setDurationEdit}
                selectedDurationHours={selectedDurationHours}
                setSelectedDurationHours={setSelectedDurationHours}
                selectedDurationMinutes={selectedDurationMinutes}
                setSelectedDurationMinutes={setSelectedDurationMinutes}
                durationView={durationView}
                isDurationEmpty={isDurationEmpty}
                isLoading={isLoading}
                isEditable={isDateTimeDurationEditable}
                setIsDurationValid={setIsDurationValid}
              />
            </>
          )}
          {showReassignmentReason && (
            <ReassignmentReason
              selectedReassignmentReason={selectedReassignmentReason}
              setSelectedReassignmentReason={setSelectedReassignmentReason}
              otherReason={otherReason}
              setOtherReason={setOtherReason}
            />
          )}
        </Box>
      </Box>
      <Box>
        <BatchNameTextField
          shouldShowBatchNameInput={shouldShowBatchNameInput}
          onChange={(event) => {
            setBatchName(event.target.value);
          }}
        />
        <Divider />
        <Submission
          handleClose={handleClose}
          handleSave={handleSave}
          disabled={isSaveDisabled}
          loading={isLoading}
        />
      </Box>
    </Drawer>
  );
}

export default EditJobDrawer;
