/* eslint-disable camelcase */
import React from 'react';
import { format } from 'date-fns';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { formatTimeRange, transformAvailabilityData } from 'lib/utils';
import {
  displayAvailability,
  displayMaxDailyHours,
} from 'components/AssociateWorkPreferences/utils';
import { AvailabilityTypography, NoResults } from './style';
import AssociateAvailabilityInfoRow from './AssociateAvailabilityInfoRow';

function AssociateDailyAvailability({ current_availability }) {
  const { palette } = useTheme();

  if (current_availability.daily_availability.length === 0) {
    return (
      <Box
        css={{
          width: '100%',
          borderBottom: `1px solid ${palette.divider}`,
          paddingBottom: '8px',
        }}
      >
        <AvailabilityTypography variant="subtitle1">
          Availability
        </AvailabilityTypography>
        <NoResults id="availability-no-result" variant="body1">
          No daily availability submitted
        </NoResults>
      </Box>
    );
  }

  const transformedAvailabilityData =
    transformAvailabilityData(current_availability);

  return (
    <Box
      css={{
        width: '100%',
        borderBottom: `1px solid ${palette.divider}`,
        paddingBottom: '8px',
      }}
      id="associate-daily-availability"
    >
      <AvailabilityTypography variant="subtitle1">
        Availability
      </AvailabilityTypography>

      <AssociateAvailabilityInfoRow label="Home store only">
        <AvailabilityTypography id="associate-home-store" variant="body1">
          {displayAvailability(current_availability.my_home_store_only)}
        </AvailabilityTypography>
      </AssociateAvailabilityInfoRow>

      {!current_availability.my_home_store_only && (
        <AssociateAvailabilityInfoRow label="Willing to drive">
          <AvailabilityTypography
            id="associate-maximum-commute-miles"
            variant="body1"
          >
            {current_availability.maximum_commute_miles === 0
              ? 'Unlimited'
              : `${current_availability.maximum_commute_miles} miles`}
          </AvailabilityTypography>
        </AssociateAvailabilityInfoRow>
      )}

      {!current_availability.my_home_store_only && (
        <AssociateAvailabilityInfoRow label="Travel and stay overnight">
          <AvailabilityTypography
            id="associate-allow-overnight-stay"
            variant="body1"
          >
            {displayAvailability(current_availability.allow_overnight_stay)}
          </AvailabilityTypography>
        </AssociateAvailabilityInfoRow>
      )}

      <AssociateAvailabilityInfoRow label="Preferred hours a week">
        <AvailabilityTypography
          id="associate-preferred-weekly-hours"
          variant="body1"
        >
          {`${current_availability.preferred_weekly_hours} hours`}
        </AvailabilityTypography>
      </AssociateAvailabilityInfoRow>

      <AssociateAvailabilityInfoRow label="Maximum hours per day">
        <AvailabilityTypography id="associate-max-daily-hours" variant="body1">
          {displayMaxDailyHours(current_availability.preferred_daily_hours)}
        </AvailabilityTypography>
      </AssociateAvailabilityInfoRow>

      {transformedAvailabilityData.map((availabilityData) => (
        <AssociateAvailabilityInfoRow
          key={`day-${availabilityData.id}`}
          label={
            availabilityData &&
            format(new Date(0, 0, availabilityData.day), 'EEEE')
          }
        >
          <Box
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
            }}
          >
            {availabilityData.time_ranges.length === 0 ? (
              <AvailabilityTypography variant="body1">
                Not Available
              </AvailabilityTypography>
            ) : (
              availabilityData.time_ranges.map((time, index) => (
                <AvailabilityTypography
                  key={`time-slot-${index}`}
                  id={`${availabilityData.day}-time-slot-${index}`}
                  variant="body1"
                >
                  {formatTimeRange(time)}
                </AvailabilityTypography>
              ))
            )}
          </Box>
        </AssociateAvailabilityInfoRow>
      ))}
    </Box>
  );
}

export default AssociateDailyAvailability;
