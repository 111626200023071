import React from 'react';
import { isSameDay, isSameMonth } from 'date-fns';
import { determineBadgeColor, determineDayAvailability } from '../utils';
import { StyledPickersDay, HighlightedDay, StyledBadge } from '../style';

function ServerDay(props) {
  const {
    selectedDay,
    day,
    overallTimeUnavailable,
    timezone,
    handleDateChange,
    ...other
  } = props;

  const isCurrentMonth = isSameMonth(day, selectedDay);
  const dayId = `calendar-${day.getDate()}`;
  const badgeColor =
    isCurrentMonth && determineBadgeColor(overallTimeUnavailable, day);

  const isSelectedDay = selectedDay && isSameDay(day, selectedDay);
  const dayAvailability = determineDayAvailability(
    overallTimeUnavailable,
    timezone,
    day
  );

  const isFullDayOff = dayAvailability === 'full_day_off';
  const isPartiallyAvailableDay = dayAvailability === 'partially_available';

  return (
    <StyledBadge
      onClick={() => handleDateChange(day)}
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={badgeColor ? <HighlightedDay color={badgeColor} /> : null}
    >
      <StyledPickersDay
        {...other}
        day={day}
        isSelectedDay={isSelectedDay}
        isPartiallyAvailableDay={isPartiallyAvailableDay}
        isFullDayOff={isFullDayOff}
        id={dayId}
      />
    </StyledBadge>
  );
}

export default ServerDay;
