import { Typography, Box } from '@mui/material';
import React from 'react';
import QuestionAccordion from './QuestionAccordion';
import { associatesQuestions } from '../constants';

function AssociatesQuestions() {
  return (
    <Box id="associates-questions-and-answers">
      {associatesQuestions.map(({ question, answer }, index) => (
        <QuestionAccordion
          defaultExpanded={index === 0}
          key={index}
          question={question}
        >
          <Typography variant="body1">{answer}</Typography>
        </QuestionAccordion>
      ))}
    </Box>
  );
}

export default AssociatesQuestions;
