import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as operations from 'state/ducks/batches/operations';
import { updateRowsPerPage } from 'state/ducks/batches/actions';
import JobID from 'components/JobTable/components/JobID';
import { updateSelectedFilterItems } from 'state/ducks/filters/actions';
import { BatchDetailsPagination, StyledZebraStripedDataGrid } from '../style';
import BatchDetailsErrorView from '../BatchDetailsErrorView';
import Breadcrumb from '../../../BreadCrumb/BreadCrumb';
import { VIEWS } from '../../../../core/config';
import BatchStatuses, { STATUS_ITEMS } from '../BatchStatuses';
import { rowsPerPageOptions } from '../../../../constants';
import BatchDetailsHeader from '../BatchDetailsHeader';

const columns = [
  {
    field: 'job_external_id',
    headerName: 'ASSIGNMENT ID',
    renderCell: ({ row }) => (
      <Box css={{ height: '100%', paddingTop: '8px', paddingBottom: '8px' }}>
        <JobID
          jobId={row.job_id}
          externalIdentifier={row.job_external_id}
          dataDogActionName="Batch Details Assignment Id Link"
        />
      </Box>
    ),
    minWidth: 160,
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'STATUS',
    renderCell: ({ value }) => (
      <Typography
        variant="body2"
        css={{
          textTransform: 'capitalize',
          height: '100%',
          paddingTop: '8px',
          paddingBottom: '8px',
        }}
      >
        {STATUS_ITEMS.map(({ label, id }) => {
          if (value === id) {
            return label;
          }
          return null;
        })}
      </Typography>
    ),
    minWidth: 160,
    sortable: false,
  },
  {
    field: 'error_message',
    headerName: 'MESSAGE',
    flex: 1,
    sortable: false,
    minWidth: 500,
    display: 'flex',
    renderCell: ({ value }) => (
      <Typography
        variant="body2"
        css={{ paddingTop: '8px', paddingBottom: '8px' }}
      >
        {value}
      </Typography>
    ),
  },
];

function BatchDetails() {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [isBatchRowsLoading, setIsBatchRowsLoading] = useState(false);
  const [isBatchDetailsLoading, setIsBatchDetailsLoading] = useState(false);
  const [isErroredBatchDetails, setIsErroredBatchDetails] = useState(false);
  const [isErroredBatchRows, setIsErroredBatchRows] = useState(false);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const isErrored = isErroredBatchDetails || isErroredBatchRows;
  const [statuses, setStatuses] = useState([]);
  const breadcrumbLinks = [
    { linkText: 'Staffing Work', href: VIEWS.private.home },
  ];

  const handleStatusClick = (selectedStatus) => {
    setStatuses(selectedStatus);
  };

  const rowsPerPage = useSelector(
    (state) => state.batchDetailsPage.cache.rowsPerPage
  );

  const params = useParams();
  const pageOfBatches = useSelector(
    (state) => state.batchDetailsPage.batch.pageOfBatches
  );
  const batchDetails = useSelector(
    (state) => state.batchDetailsPage.batch.batchDetails
  );

  const fetchPageOfBatches = () => {
    setIsBatchRowsLoading(true);
    dispatch(
      operations.fetchPageOfBatches(page, rowsPerPage, params.batchId, statuses)
    )
      .then(() => {
        setIsBatchRowsLoading(false);
        setIsErroredBatchRows(false);
      })
      .catch(() => {
        setIsBatchRowsLoading(false);
        setIsErroredBatchRows(true);
      });
  };

  const fetchBatchDetails = () => {
    setIsBatchDetailsLoading(true);
    dispatch(operations.fetchBatchDetails(params.batchId))
      .then(() => {
        setIsBatchDetailsLoading(false);
        setIsErroredBatchDetails(false);
      })
      .catch(() => {
        setIsBatchDetailsLoading(false);
        setIsErroredBatchDetails(true);
      });
  };

  useEffect(() => {
    fetchPageOfBatches(params.batchId);
  }, [params.batchId, page, rowsPerPage, statuses]);

  useEffect(() => {
    fetchBatchDetails(params.batchId);
  }, [params.batchId]);

  return (
    <Box
      id="batch-details-wrapper"
      css={{
        backgroundColor: palette.backgroundColor.secondary,
        boxShadow: '0px 4px 5px 0px #00000024',
        height: '100%',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Breadcrumb
        breadcrumbLinks={breadcrumbLinks}
        detailsText="Batch Details"
        cssOverride={{
          paddingLeft: '24px',
          paddingTop: '16px',
        }}
      />
      {isErrored && !isBatchRowsLoading ? (
        <BatchDetailsErrorView
          doRetry={() => {
            fetchPageOfBatches(params.batchId);
            fetchBatchDetails(params.batchId);
          }}
        />
      ) : (
        <>
          <BatchDetailsHeader
            batchDetails={batchDetails}
            isLoading={isBatchDetailsLoading}
          />

          <Box css={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
            <Box
              css={{
                width: '300px',
                minWidth: '300px',
                borderRight: '1px solid #C6CACE',
                padding: '16px',
                boxSizing: 'border-box',
              }}
            >
              <BatchStatuses
                successfulRows={batchDetails.successful_rows}
                failedRows={batchDetails.failed_rows}
                unknownRows={batchDetails.unknown_rows}
                handleStatusClick={handleStatusClick}
                staffedRows={batchDetails.staffed_rows}
                notStaffedRows={batchDetails.not_staffed_rows}
                statuses={statuses}
              />

              <Divider css={{ margin: '24px 0', borderColor: '#C6CACE' }} />
              <Button
                id="edit-auto-batch-assignment"
                css={{ width: '100%', height: '42px' }}
                variant="contained"
                onClick={() => {
                  dispatch(
                    updateSelectedFilterItems({
                      centralMode: new Map([['single', 'single']]),
                    })
                  );
                  navigate(`assignments`);
                }}
              >
                EDIT ASSIGNMENT(S)
              </Button>
            </Box>
            <StyledZebraStripedDataGrid
              className="data-grid"
              rows={pageOfBatches}
              columns={columns}
              getRowHeight={() => 'auto'}
              rowCount={pageOfBatches.length}
              disableColumnMenu
              disableColumnFilter
              disableColumnSorting
              disableRowSelectionOnClick
              paginationMode="server"
              loading={isBatchRowsLoading}
              getRowId={(row) => row.job_id}
              localeText={{
                noRowsLabel: 'There are no batch details to display',
              }}
              css={{
                '.MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
              }}
              slots={{
                // eslint-disable-next-line react/no-unstable-nested-components
                footer: () => (
                  <>
                    <Divider />
                    <Box
                      css={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                        height: '52px',
                      }}
                    >
                      <BatchDetailsPagination
                        component="div"
                        page={page}
                        count={1000000000}
                        onPageChange={(event, newPage) => {
                          setPage(newPage);
                        }}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(event) => {
                          setPage(0);
                          dispatch(updateRowsPerPage(event.target.value));
                        }}
                        rowsPerPageOptions={rowsPerPageOptions}
                        labelDisplayedRows={({ from, to }) => `${from}–${to}`}
                      />
                    </Box>
                  </>
                ),
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default BatchDetails;
