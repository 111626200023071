import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React from 'react';

function QuestionAccordion({ question, defaultExpanded, children }) {
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMore fontSize="medium" />}
        aria-controls={`faq-${question.toLowerCase().replace(/\s+/g, '-')}-content`}
        id={`faq-${question.toLowerCase().replace(/\s+/g, '-')}-header`}
      >
        <Typography variant="subtitle3">{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{children}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default QuestionAccordion;
