import React, { useState, useEffect } from 'react';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Box } from '@mui/material';
import {
  isSameDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  endOfDay,
} from 'date-fns';
import axios from 'axios';
import MonthAndWeekdaySelector from './MonthDaySelector';
import ScheduleCollapsableHeader from './ScheduleCollapsableHeader';
import LoadingSpinner from './LoadingSpinner';
import Error from './Error';
import {
  getAssociateSchedule,
  getAssociateTimeOff,
} from '../../../services/user';

function WorkSchedule({
  selectedDay,
  setSelectedDay,
  associate,
  monthlyAvailability,
}) {
  const [isLoadingSchedule, setIsLoadingSchedule] = useState(false);
  const [isLoadingTimeOff, setIsLoadingTimeOff] = useState(false);
  const [scheduleJobs, setScheduleJobs] = useState([]);
  const [overallTimeUnavailable, setOverallTimeUnavailable] = useState([]);
  const [timeOff, setTimeOff] = useState([]);
  const [unavailableTimesError, setUnavailableTimesError] = useState(null);
  const [timeOffError, setTimeOffError] = useState(null);
  const isLoading = isLoadingSchedule || isLoadingTimeOff;
  const isError = unavailableTimesError || timeOffError;

  const [monthRange, setMonthRange] = useState({
    start: startOfMonth(selectedDay),
    end: endOfMonth(selectedDay),
  });

  const handleDayChange = () => {
    setIsLoadingSchedule(true);
    setOverallTimeUnavailable([]);
    setScheduleJobs([]);
    setTimeOff([]);
    const effectiveStartDate = zonedTimeToUtc(
      startOfDay(monthRange.start),
      associate.profile.timezone
    );
    const endEffectiveDate = zonedTimeToUtc(
      endOfDay(monthRange.end),
      associate.profile.timezone
    );

    getAssociateSchedule({
      userId: associate.id,
      effectiveDate: effectiveStartDate,
      endEffectiveDate,
      timezone: associate.profile.timezone,
    })
      .then((schedules) => {
        setIsLoadingSchedule(false);
        const formattedSchedules = schedules.map((schedule) => ({
          start_datetime: schedule.job_start_after,
          datetime: schedule.job_start_after,
          end_datetime: schedule.job_finish_before,
          store_location_timezone: schedule.store_location_timezone,
          isOvernight: !isSameDay(
            utcToZonedTime(
              new Date(schedule.job_start_after),
              associate.profile.timezone
            ),
            utcToZonedTime(
              new Date(schedule.job_finish_before),
              associate.profile.timezone
            )
          ),
          ...schedule,
        }));

        setScheduleJobs(formattedSchedules);
      })
      .catch((error) => {
        setIsLoadingSchedule(false);
        if (!axios.isCancel(error)) {
          setUnavailableTimesError(error.message);
        }

        setUnavailableTimesError(error);
      });
    setIsLoadingTimeOff(true);
    getAssociateTimeOff({
      userId: associate.id,
      effectiveDate: effectiveStartDate,
      endEffectiveDate,
      timezone: associate.profile.timezone,
    })
      .then((timeOffSchedules) => {
        setIsLoadingTimeOff(false);
        const formattedTimeOff = timeOffSchedules.map((timeOffSchedule) => ({
          datetime: timeOffSchedule.start_datetime,
          ...timeOffSchedule,
        }));
        setTimeOff(formattedTimeOff);
      })
      .catch((error) => {
        setIsLoadingTimeOff(false);
        if (!axios.isCancel(error)) {
          setTimeOffError(error.message);
        }

        setTimeOffError(error);
      });
  };

  useEffect(() => {
    handleDayChange();
  }, [monthRange]);

  useEffect(() => {
    const timeOffDates = new Set(
      timeOff.map((item) => {
        const date = new Date(item.datetime);
        return date.toDateString();
      })
    );

    const filteredDayAvailability = monthlyAvailability.filter((item) => {
      const date = new Date(item.datetime).toDateString();
      return !timeOffDates.has(date);
    });

    const combinedTimeOff = [
      ...scheduleJobs,
      ...timeOff,
      ...filteredDayAvailability,
    ].sort(
      (first, second) =>
        new Date(first.datetime).getTime() - new Date(second.datetime).getTime()
    );

    setOverallTimeUnavailable(combinedTimeOff);
  }, [scheduleJobs, timeOff, monthlyAvailability]);
  if (isLoading) {
    return (
      <Box
        style={{
          borderTop: 'outset',
          padding: '24px',
        }}
      >
        <LoadingSpinner id="associate-schedule-loading" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        style={{
          borderTop: 'outset',
          padding: '24px',
        }}
      >
        <Error
          id="associate-schedule-error"
          doRetry={() => {
            setUnavailableTimesError(null);
            setTimeOffError(null);
            handleDayChange();
          }}
        />
      </Box>
    );
  }

  return (
    <>
      <MonthAndWeekdaySelector
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        overallTimeUnavailable={overallTimeUnavailable}
        timezone={associate.profile.timezone}
        setMonthRange={setMonthRange}
      />
      <ScheduleCollapsableHeader
        associate={associate}
        selectedDay={selectedDay}
        overallTimeUnavailable={overallTimeUnavailable}
      />
    </>
  );
}

export default WorkSchedule;
