/* eslint-disable prettier/prettier */
import React from 'react';
import { AlertTitle } from '@mui/material';
import { useSelector } from 'react-redux';
import { VARIANTS } from '../../../constants';
import { StyledAlert } from '../../../styles/components';

function AutoHomeStoreOnlyAlert() {
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const currentMode = Array.from(centralMode.values())[0];

  if (currentMode === 'auto_home_store_only') {
    return (
      <StyledAlert
        severity={VARIANTS.info}
        variant="filled"
      >
        <AlertTitle>Notice</AlertTitle>
        Only assignments at a location with at least 1 active home store
        Teammate are shown and selected.
      </StyledAlert>
    );
  }

  return null;
}

export default AutoHomeStoreOnlyAlert;
