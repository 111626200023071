/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useDeepCompareEffect from 'use-deep-compare-effect';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import { enqueueSnackbar } from 'notistack';
import { getJobDetails } from 'services/jobs';
import { updateSingleRow } from 'components/JobTable/utils';
import { DrawerErrorWrapper } from '../../styles/components';
import Header from './components/Header';
import { getMatchedAssociates, putAssociate } from '../../services/associates';
import { ErrorCloseButton } from './style';
import { ASSOCIATE_ASSIGNED_SUCCESS_TEXT } from '../HomePage/constants';
import { formatErrorFromSubsystem, getUpdatedJob } from '../../lib/utils';
import { updateRowsPerPage } from '../../state/ducks/suggestAssociate/actions';
import SelectedAssociate from './components/SelectedAssociate';
import Associates from './components/Associates';
import Footer from './components/Footer';
import { associateDrawerWidth } from './constants';
import { VARIANTS } from '../../constants';
import { setSelectedEditField } from '../../state/ducks/staffingWorkPage/actions';
import { VIEWS } from '../../core/config';

function ShowError({ msg, doRetry }) {
  return (
    <DrawerErrorWrapper id="matched-associates-error">
      <Typography variant="subtitle1" color="error">
        {msg}
      </Typography>
      <Button size="small" variant="outlined" color="primary" onClick={doRetry}>
        Retry
      </Button>
    </DrawerErrorWrapper>
  );
}

let abortController = new AbortController();

function SuggestAssociates({
  setSelectedAssociate,
  gridApiRef,
  page,
  setPage,
  errorMessage,
  setErrorMessage,
  isLoading,
  setIsLoading,
  associates,
  setAssociates,
  onClose,
}) {
  const theme = useTheme();
  const lastAdvancedSearchOptions = useSelector(
    (state) => state.suggestAssociate.advancedSearch
  );
  const [currentAdvancedSearchOptions, setCurrentAdvancedSearchOptions] =
    useState(lastAdvancedSearchOptions);
  const suggestAssociateJob = useSelector(
    ({ suggestAssociate: { job } }) => job
  );
  const dispatch = useDispatch();
  const jobId = suggestAssociateJob.id;
  const batchJobs = useSelector(
    (state) => state.staffingWorkPage.editJob.batchJobs
  );

  const pageSize = useSelector(
    (state) => state.suggestAssociate.cache.rowsPerPage
  );

  const flagName = 'use_priority';
  const showInternalFAQPage = useSelector(
    (state) => state.featureFlags[flagName]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const loadMatchedAssociates = () => {
    if (jobId) {
      abortController.abort();
      abortController = new AbortController();
      const { signal } = abortController;

      setErrorMessage(null);
      setIsLoading(true);

      getMatchedAssociates(
        {
          jobId,
          page: page + 1,
          pageSize,
          ...lastAdvancedSearchOptions,
        },
        signal
      )
        .then((data) => {
          setIsLoading(false);
          setAssociates(data.results);
        })
        .catch((error) => {
          if (signal && !signal.aborted) {
            setErrorMessage(
              error.message || 'There was a problem retrieving the Job record'
            );
            setIsLoading(false);
          }
        });
    }
  };

  const handleAssignAssociateClick = (associateId, associateName) => {
    if (batchJobs.length === 1) {
      setSelectedAssociate({
        ids: [associateId],
        jobIds: batchJobs,
        name: associateName,
      });
      onClose();
      dispatch(setSelectedEditField('associate'));
    } else if (jobId) {
      setIsLoading(true);
      putAssociate({ associateId, jobId })
        .then(() => {
          enqueueSnackbar(ASSOCIATE_ASSIGNED_SUCCESS_TEXT, {
            variant: VARIANTS.success,
          });
          onClose();
          getUpdatedJob(jobId, gridApiRef, getJobDetails, updateSingleRow);
        })
        .catch((error) => {
          setIsLoading(false);
          enqueueSnackbar(
            formatErrorFromSubsystem(
              'Error when assigning or un-assigning',
              error.response.data.detail,
              suggestAssociateJob.external_identifier,
              suggestAssociateJob.advantage_source_system_name
            ),
            { variant: VARIANTS.error }
          );
        });
    }
  };

  useEffect(() => {
    setPage(0);
    setCurrentAdvancedSearchOptions(lastAdvancedSearchOptions);
  }, [suggestAssociateJob.id]);

  useDeepCompareEffect(() => {
    loadMatchedAssociates();
  }, [page, pageSize, lastAdvancedSearchOptions]);

  if (errorMessage) {
    return (
      <Box css={{ width: associateDrawerWidth }}>
        <ErrorCloseButton>
          <Button onClick={onClose}>
            <CloseIcon />
          </Button>
        </ErrorCloseButton>
        <ShowError msg={errorMessage} doRetry={loadMatchedAssociates} />
      </Box>
    );
  }
  return (
    <Box
      id="suggest-associates"
      css={{
        width: associateDrawerWidth,
        height: '100%',
        backgroundColor: 'rgba(11, 59, 96, 0.04)',
        overflowY: 'scroll',
      }}
    >
      <Header
        assignmentId={suggestAssociateJob.external_identifier}
        setPage={setPage}
        lastAdvancedSearchOptions={lastAdvancedSearchOptions}
        currentAdvancedSearchOptions={currentAdvancedSearchOptions}
        setCurrentAdvancedSearchOptions={setCurrentAdvancedSearchOptions}
        isLoading={isLoading}
        jobId={suggestAssociateJob.id}
        onClose={onClose}
        homeSystem={suggestAssociateJob.advantage_source_system_name}
      />
      <SelectedAssociate />
      <Divider />
      <Box
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 16px',
        }}
      >
        <Typography variant="subtitle1">Match Results</Typography>
        {showInternalFAQPage && (
          <Typography variant="subtitle1" css={{ fontWeight: 400 }}>
            <Link
              css={{ color: theme.palette.primary.main }}
              to={VIEWS.private.faq}
              id="suggest-associates-match-link"
            >
              Match Rules
            </Link>
          </Typography>
        )}
      </Box>
      <Divider />
      <Associates
        associates={associates}
        isLoading={isLoading}
        handleAssignAssociateClick={handleAssignAssociateClick}
      />
      <Footer
        page={page}
        rowsPerPage={pageSize}
        onChange={handleChangePage}
        onRowsPerPageChange={(event) => {
          setPage(0);
          dispatch(updateRowsPerPage(event.target.value));
        }}
      />
    </Box>
  );
}

export default SuggestAssociates;
